import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public jwtHelper: JwtHelperService = new JwtHelperService();
  private user$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private school$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private selectedBranch$: BehaviorSubject<any> = new BehaviorSubject<any>(
    'allBranches',
  );

  private selectedAcademicYear$: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);

  constructor(
    private generic: GenericService, //public jwtHelper: JwtHelperService
  ) {
    this.getUser().subscribe((res: any) => {
      if (res == null) {
        this.school$.next(null);
        this.selectedBranch$.next('allBranches');
      }
    });
    const selectedBranch = localStorage.getItem('selectedBranch');
    if (selectedBranch != undefined) this.selectedBranch$.next(selectedBranch);
    const school = localStorage.getItem('fbUrl');
    if (school != undefined) this.school$.next(school);
    else this.setSchoolInfo('SimpliEdTech');
  }

  getUser() {
    return this.user$.asObservable();
  }

  getSelectedBrnach() {
    return this.selectedBranch$.asObservable().pipe(distinctUntilChanged());
  }

  getSelectedAcademicYear() {
    return this.selectedAcademicYear$
      .asObservable()
      .pipe(distinctUntilChanged());
  }

  setSchoolInfo(value: any) {
    localStorage.setItem('fbUrl', value);
    this.school$.next(value);
  }

  getSchoolInfo() {
    return this.school$.asObservable().pipe(distinctUntilChanged());
  }

  setSelectedBranch(selectedBranch) {
    localStorage.setItem('selectedBranch', selectedBranch);
    this.selectedBranch$.next(selectedBranch);
  }

  setSelectedAcademicYear(selectedAcademicYear) {
    localStorage.setItem('selectedAcademicYear', selectedAcademicYear);
    this.selectedAcademicYear$.next(selectedAcademicYear);
  }

  login(body) {
    return this.generic
      .getDataByPost('auth', body)
      .pipe(tap((res) => this.user$.next(res['data'])));
  }
  logout() {
    return this.generic
      .getDataByPost('auth/logout', {})
      .pipe(tap((res) => this.user$.next(null)));
  }
  // for forgot password
  forgotPassword(body) {
    return this.generic.getDataByPost('auth/forgot', body);
  }
  // for forgot password
  forgotPasswordRollNo(body) {
    return this.generic.getDataByPost('auth/forgot/rollno', body);
  }
  // check token validiation
  checkResetPasswordToken(token) {
    return this.generic.getDataByPost('auth/checkResetTokenExpiry', token);
  }

  //reset Password

  resetPassword(body) {
    return this.generic.getDataByPost('auth/resetpass', body);
  }

  //change Password

  changePassword(body) {
    return this.generic.getDataByPost('auth/changepass', body);
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  checkPermissions(routeData) {
    const menuItems = JSON.parse(localStorage.getItem('menuitems'));
    if (!menuItems) {
      return false;
    }
    if (routeData.module === undefined) return false;
    if (
      routeData.feature == undefined &&
      !menuItems.find(
        (x) => x.name.toUpperCase() == routeData.module.toUpperCase(),
      )
    ) {
      return false;
    }
    if (
      routeData.feature != undefined &&
      routeData.feature != 'dashboard' &&
      !menuItems.find((x) =>
        x.features.some(
          (item) =>
            x.name.toUpperCase() === routeData.module.toUpperCase() &&
            item.name.toUpperCase() === routeData.feature.toUpperCase(),
        ),
      )
    ) {
      return false;
    }
    return true;
  }

  checkPermissionsV2(routeData) {
    const navigation = JSON.parse(localStorage.getItem('navigation'));
    if (!navigation || !navigation.length) {
      return false;
    }

    const matchingModule = navigation.find(
      (module) =>
        module.module &&
        module.module.name.toUpperCase() === routeData.module.toUpperCase(),
    );

    if (!matchingModule) {
      return false;
    }

    if (routeData.feature && routeData.feature !== 'dashboard') {
      const matchingFeature = matchingModule.features.find(
        (feature) =>
          feature.name.toUpperCase() === routeData.feature.toUpperCase(),
      );

      if (!matchingFeature) {
        return false;
      }
    }

    return true;
  }
}
