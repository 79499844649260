import { BehaviorSubject } from 'rxjs';
import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CurriculumService {
  private readonly controller = 'curriculum';
  private readonly classController = 'classes';
  private readonly uploadPdfEndpoint = 'upload-attachment';
  private readonly deletePdfEndpoint = 'delete-attachment';
  private courseDetail$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private generic: GenericService) {}

  get() {
    return this.generic.getData(this.controller);
  }

  getByID(id, force = false) {
    if (
      (this.courseDetail$.getValue() != null &&
        this.courseDetail$.getValue().data._id != id) ||
      force
    ) {
      this.courseDetail$.next(null);
    }
    return this.courseDetail$.getValue()
      ? this.courseDetail$.asObservable()
      : this.set(id);
  }

  private set(id) {
    return this.generic
      .getDataById(this.controller, id)
      .pipe(tap((response) => this.courseDetail$.next(response)));
  }

  getByParams(param) {
    return this.generic.getData(this.controller + '?' + param);
  }

  getByGrade(gradeId) {
    return this.generic.getData(`${this.controller}/${gradeId}`);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  update(body, id) {
    this.courseDetail$.next(null);
    return this.generic.update(`${this.controller}/${id}`, body);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }

  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }

  applyAssignSetting(body) {
    return this.generic.create(
      `${this.controller}/${
        this.courseDetail$.getValue().data._id
      }/assign-settings`,
      body,
    );
  }

  assignAssignment(assignmentId, body) {
    return this.generic.create(
      `${this.controller}/${
        this.courseDetail$.getValue().data._id
      }/assignments/${assignmentId}/assign-assignment`,
      body,
    );
  }

  unAssignAssignment(assignmentId, body) {
    return this.generic.create(
      `${this.controller}/${
        this.courseDetail$.getValue().data._id
      }/assignments/${assignmentId}/unassign-assignment`,
      body,
    );
  }

  uploadDocument(curriculumId, body) {
    return this.generic.imageUpload(
      `${this.controller}/${curriculumId}/${this.uploadPdfEndpoint}`,
      body,
    );
  }

  deleteDocument(curriculumId, url) {
    return this.generic.deleteWithOutId(
      `${this.controller}/${curriculumId}/${this.deletePdfEndpoint}?fileUrl=${url}`,
    );
  }

  assignStandard(body) {
    return this.generic.create(
      `${this.controller}/${
        this.courseDetail$.getValue().data._id
      }/assign-standards`,
      body,
    );
  }
}
