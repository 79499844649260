import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AdmissionFeeStructureService {
  private readonly controller = 'admission/fee-structure';

  constructor(private generic: GenericService) {}

  get() {
    return this.generic.getData(this.controller);
  }

  getBillingHeads() {
    return this.generic.getData('admission/billingheads');
  }

  getByParams(param) {
    return this.generic.getData(this.controller + '?' + param);
  }

  post(body: any) {
    return this.generic.create(this.controller, body);
  }

  getById(id) {
    return this.generic.getDataById(this.controller, id);
  }

  update(id: string, body: any) {
    return this.generic.update(`${this.controller}/${id}`, body);
  }

  delete(id: string) {
    return this.generic.deleteWithOutId(`${this.controller}/${id}`);
  }
}
